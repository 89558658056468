.lifes-label {
  display: flex;
  align-items: center;
  border-radius: 30px;
  padding-left: 10px;
  background: #D3E699;

  &__icon {
    width: 20px;
    height: 17px;
    margin-right: 6px;
  }

  &__font {
    font-variant-numeric: lining-nums proportional-nums;
    color: black;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}