.signs {
  $parent: &;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 161px;
  row-gap: 60px;
  border-radius: 30px;
  padding: 20px;
  background-color: #FFCF99;

  @media screen and (max-width: 1199px) {
    width: calc(100% - 30px);
    padding: 15px;
  }

  @media screen and (max-width: 768px) {
    column-gap: 10px;
    padding: 20px;
    row-gap: 40px;
    justify-content: space-between;
    width: 100%;
    height: auto;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
    min-width: 0;

    &:disabled {
      pointer-events: none;
    }

    @media screen and (max-width: 1350px) {
      gap: 4px;
    }
    @media screen and (max-width: 769px) {
      gap: 10px;
    }
    @media screen and (max-width: 568px) {
      gap: 5px;
    }
  }

  &__image-wrapper {
    position: relative;
    height: 96px;
  }

  &__image {
    object-fit: contain;
    width: 164px;
    height: 100%;

    @media screen and (max-width: 768px) {
      width: 90px;
    }

    @media screen and (max-width: 390px) {
      width: 65px;
    }
  }

  &__icon {
    position: absolute;
    left: 10px;
    bottom: -5px;
    width: 25px;
    height: 25px;

    @media screen and (max-width: 768px) {
      width: 17px;
      height: 17px;
      left: 7px;
      bottom: -2px;
    }
  }

  &__name {
    text-align: center;

    @media screen and (max-width: 568px) {
      hyphens: auto;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      -o-hyphens: auto;
    }
  }

  &__font {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    text-transform: uppercase;

    @media screen and (max-width: 1440px) {
      font-size: 12px;
      line-height: 16px;
    }
    @media screen and (max-width: 1199px) {
      font-size: 12px;
      line-height: 14px;
    }
    @media screen and (max-width: 768px) {
      font-size: 14px;
      line-height: 18px;
    }
    @media screen and (max-width: 568px) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &--size {
    &--medium {
      column-gap: 8px;
      row-gap: 5px;

      #{$parent} {
        &__item {
          height: auto;
        }
        @media screen and (max-width: 776px) {
          height: 180px;
        }

        &__image {
          width: 103px;

          @media screen and (max-width: 1199px) {
            width: 100%;
            max-width: 96px;
          }
          @media screen and (max-width: 768px) {
            max-width: 90px;
          }

          @media screen and (max-width: 390px) {
            max-width: 65px;
          }
        }
      }
    }
  }
}