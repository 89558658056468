.reload-button {
  $parent: &;

  border-radius: 15px;
  border: 2px solid #000;
  background: #FFCF99;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s;
  padding: 12px 20px;

  &:hover {
    background-color: black;

    #{$parent} {
      &__font {
        color: white;
      }

      &__icon {
        color: white;
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin-left: 20px;
    color: black;
    transition: color 0.3s;
  }

  &__font {
    color: black;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    transition: color 0.3s;
  }
}