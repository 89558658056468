.answer-popup {
  border-radius: 30px;
  border: 3px solid black;
  display: flex;
  flex-direction: column;
  padding: 15px 20px 25px;

  @media screen and (max-width: 768px) {
    padding: 10px;
    border-radius: 15px;
  }

  &__icon {
    width: 35px;
    height: 35px;
    margin-right: 11px;

    @media screen and (max-width: 768px) {
      width: 26px;
      height: 26px;
      margin-right: 12px;
    }
  }

  &__head {
    display: flex;
    align-items: center;
    margin: 0 auto 20px;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  &__content {
    max-height: 100%;
    overflow-y: auto;
    margin-bottom: 20px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__button {
    margin: auto auto 0;
  }

  &__font {
    color: black;
    font-size: 25px;
    font-style: normal;
    line-height: normal;

    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }

  &--theme {
    &--green {
      background-color: #E8FE87;
    }

    &--pink {
      background-color: #F7CDD6;
    }
  }
}