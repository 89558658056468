.timer {
  position: relative;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background-color: #FFCF99;

  &__circle {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    width: 65px;
    height: 65px;
    z-index: 1;
  }

  &__time {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    z-index: 2;
  }

  &__font {
    color: black;
    text-align: center;
    text-transform: uppercase;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
  }
}
