.game-navigate-media {
  &__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 30px 60px;

    @media screen and (max-width: 996px) {
      padding: 30px;
    }

    @media screen and (max-width: 768px) {
      padding: 30px 20px;
    }
  }

  &__start, &__explanation {
    margin: auto;
  }

  &__thanks {
    margin-top: auto;
    margin-bottom: auto;
  }

  &__game {
    width: 100%;
    height: 100%;
    flex-grow: 1;
  }

  &__test {
    background-color: #1ac23f;
    color: whitesmoke;
    width: 400px;
    height: 200px;
  }

  &__test-img {
    width: 100px;
  }
}