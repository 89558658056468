.round-modal {
  max-width: 690px;
  min-height: 248px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 30px;
  border: 2px solid #000;
  background: #FFB200;
  padding: 30px 40px;

  @media screen and (max-width: 768px) {
    padding: 20px;
    min-height: 200px;
  }

  &__image {
    width: 200px;
    height: 139px;
    object-fit: contain;
    margin-bottom: 30px;

    @media screen and (max-width: 768px) {
      width: 120px;
      height: 85px;
      margin-bottom: 20px;
    }
  }

  &__title {
    text-align: center;
    margin-bottom: 50px;

    @media screen and (max-width: 768px) {
      margin-bottom: 30px;
    }
  }

  &__button {
    margin-top: auto;
  }

  &__font {
    color: #2E422C;
    font-size: 70px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
    font-variant-numeric: lining-nums proportional-nums;

    @media screen and (max-width: 768px) {
      font-size: 46px;
    }
  }
}