.layout-header {
  $parent: &;

  display: flex;
  align-items: center;
  background-color: #FF8700;
  padding: 30px 20px;

  @media screen and (max-width: 768px) {
    padding: 15px 20px;
  }


  &__logo {
    width: 211px;
    height: 50px;
    margin-right: 23px;

    @media screen and (max-width: 768px) {
      width: 152px;
      height: 37px;
    }
  }


  &__wrapper {
    &--buttons {
      display: flex;
      align-items: center;
      margin-left: auto;

      @media screen and (max-width: 768px){
        align-self: flex-end;
      }
    }
  }

  &__button {
    margin-right: 60px;

    @media screen and (max-width: 768px){
      display: none;
    }
  }

  &__line {
    width: 100%;
    height: 3px;
    background-color: black;

    &:not(:last-child) {
      margin-bottom: 9px;
    }
  }

  &__font {
    &--title {
      font-size: 19px;
      font-weight: 500;
      line-height: 35px;
      color: black;

      @media screen and (max-width: 768px) {
        font-size: 14px;
        font-weight: 500;
        line-height: 15px;
      }
    }
  }
}