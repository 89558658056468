.circle-progress {
  rotate: -90deg;

  &__bar {
    fill: none;
    stroke: #9CBA42;
    stroke-dasharray: 100 100;
    stroke-width: 8px;
    transition: 1s linear;

    &--full {
      stroke: #D3E699;
    }
  }
}
