.round-label {
  padding: 5px 15px;
  border-radius: 30px;
  background: #D3E699;

  &__font {
    color: black;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    font-variant-numeric: lining-nums proportional-nums;
  }
}