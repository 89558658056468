.layout-default {
  position: relative;
  background-color: #FF8700;
  padding: 0 0 40px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &__header {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }

  &__content {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    &--hidden {
      overflow: hidden;
    }
  }

  &__button {
    margin: 0 auto;
    @media screen and (min-width: 769px) {
      display: none;
    }
  }
}