.thank-you {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__wrapper {
    border-radius: 30px;
    max-width: 690px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFF;
    padding: 40px;
    margin-bottom: 30px;

    @media screen and (max-width: 768px) {
      padding: 20px;
      max-width: 100%;
    }
  }

  &__image {
    width: 200px;
    height: 84px;
    object-fit: contain;
    margin-bottom: 30px;

    @media screen and (max-width: 768px) {
      width: 105px;
      height: 44px;
      margin-bottom: 20px;
    }
  }

  &__title {
    text-align: center;
    margin-bottom: 20px;
  }

  &__text {
    text-align: center;
  }

  &__button {
    margin: auto auto 0;
  }

  &__font {
    &--title {
      color: #000;
      font-size: 50px;
      font-style: normal;
      font-weight: 500;
      line-height: 1;
      text-transform: uppercase;

      @media screen and (max-width: 768px) {
        font-size: 29px;
      }
    }

    &--text {
      color: #000;
      font-size: 25px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;

      @media screen and (max-width: 768px) {
        font-size: 20px;
      }
    }
  }
}