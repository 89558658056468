.game-navigate-start {
  max-width: 690px;
  width: 100%;

  &__wrapper {
    width: 100%;
    display: flex;
    border-radius: 30px;
    border: 2px solid #000;
    background: white;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;

    @media screen and (max-width: 768px) {
      border: none;
      padding: 20px;
    }
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
  }
  &__button {
    margin: 30px auto 0;

    @media screen and (max-width: 768px) {
      margin-top: 20px;
    }
  }

  &__font {
    color: #000;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;

    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
}